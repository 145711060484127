export default {
  title: {
    en: 'Sorry,<br/>page not found',
  },
  text: {
    en: 'Sorry, the page you are looking for doesn’t exist. Stay calm, take a deep breath, and smile.',
  },
  buttonText: {
    en: 'Go to our main page',
  },
}
